export function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        console.log("Registering service worker successfully")
        navigator.serviceWorker.register('/my-service-worker.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                console.error('Service Worker registration failed:', error);
            });
    }
}

export function requestNotificationPermission() {
    if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log("Notifications permission granted")
            } else if (permission === 'denied') {
                console.log("Notifications permission denied")
            } else if (permission === 'default') {
                console.log("Notifications permission not set")
            }
        });
    }
}

export async function getSubscription() {
    try {
        const registration = await navigator.serviceWorker.ready
        if (registration) {
            console.log("Is worker registered? True")
            const subscription = await registration.pushManager.getSubscription();
            return subscription
        } else {
            console.log("Worker is not registered")
        }
    } catch (error) {
        console.log("Error getting subscription:", error); // Or throw the error, depending on your error handling strategy
    }
}

export async function subscribeForNotifications() {
    try {
        console.log("Subscribing for notifications")
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.getRegistration()
            var curSubscription = await getSubscription()
            if (curSubscription === null) {
                console.log("Subscring google")
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    /*applicationServerKey: 'BBDq9NtQJPyxYh6J92LHErHi662GpTise9yuRuDmfvwjlLaL2x1dihBzeOkuU_VuieSWRkxkgXJnMA00wnAN_Sg',*/
                    /*applicationServerKey: 'BNLhMKuCoHJXPR8aSz2M_TWmZn3Ntvu3iSVUxMZBqXHO6C5fKf7CXSJuPRNArn5fzDMELnTWNK4Ndt5HuCPeMsM',*/
                    applicationServerKey: 'BGXj9GWYciePeItvO_RJSWbroFDy46orHKB5gvRF_S2LNhrGaP9Ri6acNwqJRrQN7zXlXWqPqhHIKZa0EfOBBbE'                    
                });
                curSubscription = subscription
                console.log("Subscribed to google:", curSubscription)
            }

            //This can be (and is) cached because its GET request
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(backendUrl + `?api=is_client_subscribed&endpoint=${curSubscription.endpoint}`, {
                method: 'GET',
            });

            const result = await response.json();
            console.log("Is subscribed in backend response: ", result)

            if (result.is_subscribed === false) {
                console.log("Subscribing in backend")
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const registerResponse = await fetch(backendUrl + '?api=subscribe', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 'subscription_record': curSubscription }),
                });
                console.log("Subscribe in backend response: ", registerResponse)
            }
        }
    } catch (error) {
        console.error('Push subscription failed:', error);
    }
}
