import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { getSubscription } from '../MyServiceWorkerHandler';
import Select from 'react-select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


const UserSettings = () => {

  const [isShabatAlertActive, setIsShabatAlertActive] = useState(true);
  const [isWeekAlertActive, setIsWeekAlertActive] = useState(true);
  const [selectedCity, setSelectedCity] = useState('TelAviv');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;



  useEffect(() => {
    getSubscription().then((subscription) => {
      fetch(backendUrl + '?api=get_subscription_settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ endpoint: subscription['endpoint'] })
      }).then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            setIsShabatAlertActive(result['client_settings']['is_shabat_alert_active'])
            setIsWeekAlertActive(result['client_settings']['is_week_alert_active'])
            setSelectedCity(result['client_settings']['city'])
          },
          (error) => {
            console.log(error)
          }
        )
    })
  }, [])


  const shabatAlertSwitchHandler = (event) => {
    setIsShabatAlertActive(event.target.checked);
  };

  const weekAlertSwitchHandler = (event) => {
    setIsWeekAlertActive(event.target.checked);
  };


  const applySettings = () => {
    getSubscription().then((subscription) => {
      fetch(backendUrl + '?api=subscription_settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ endpoint: subscription['endpoint'], client_settings: { is_shabat_alert_active: isShabatAlertActive, is_week_alert_active: isWeekAlertActive, city: selectedCity } })
      });
    })
  }

  return (
    <div className='bg-[#ffffff60] shadow-lg w-10/12 h-[90%] rounded-xl flex flex-col items-center gap-6 text-lg'>


      <h1 className='tracking-wide mt-16 font-bold text-3xl pt-4'> הגדרות </h1>

      {/* White box */}
      <div className='flex flex-col w-10/12 h-60 overflow-hidden rounded-xl mt-6'>
        {/* Title */}
        <div className='bg-[#ffffff90] pt-1 w-full h-10 font-medium' >תזכורת שבת</div>

        {/* Setting Boxes Container */}
        <div className="flex flex-col w-full justify-between h-56">
          {/* Before Shabat Setting */}
          <div className="flex items-center w-full h-1/3">
            <div className={`w-9/12 h-full flex items-center justify-center ${isShabatAlertActive ? 'bg-[#ffffff60]' : 'bg-[#aaaaaa40]'}`}>
              <span> קבל הודעות כניסת שבת </span>
            </div>
            <div className="w-3/12 h-full flex bg-[#ffffff60] justify-center items-center">
              <Switch checked={isShabatAlertActive ? true : false} onChange={shabatAlertSwitchHandler} />
            </div>
          </div>
          {/* After Shabat Setting */}
          <div className="flex items-center w-full h-1/3">
            <div className={`w-9/12 h-full flex items-center justify-center ${isWeekAlertActive ? 'bg-[#ffffff60]' : 'bg-[#aaaaaa40]'}`}>
              <span> קבל הודעות שבוע טוב </span>
            </div>
            <div className="w-3/12 h-full flex bg-[#ffffff60] justify-center items-center">
              <Switch checked={isWeekAlertActive ? true : false} onChange={weekAlertSwitchHandler} />
            </div>
          </div>

          {/* City Setting */}

          <div className={`w-full h-1/3 flex items-center justify-center bg-[#ffffff60]`}>
              <span className='w-1/5'> עיר </span>

              <select value={selectedCity} onChange={(event) => {setSelectedCity(event.target.value)}} className='w-3/5 bg-white'>
                <option value="TelAviv">תל אביב</option>
                <option value="Haifa">חיפה</option>
                <option value="Jerusalem">ירושלים</option>
                <option value="BeerSheva">באר שבע</option>
              </select>

            </div>


        </div>
      </div>

      <button className="w-4/12 bg-[#ffffff60] border-[#ffffffc0] rounded-lg border-2 mt-6 active:bg-[#ffffff20]" onClick={applySettings}> אישור </button>

    </div>
  )
}

export default UserSettings
