import React from 'react'

const About = () => {
  return (

    <div className='bg-[#ffffff60] shadow-lg w-10/12 h-[90%] flex flex-col items-center gap-6 text-lg'>
      <h1 className='tracking-wide mt-16 font-bold text-3xl pt-4'> קצת עלינו </h1>
      

      <p>
      ברוכים הבאים ל"שבת שלי"
      <br/><br/>
      ב"שבת שלי" אנו שואפים לשפר את אווירת החג והשבת כמה שרק אפשר 
      באפליקציה תוכלו להתעדכן בקלות בזמני כניסת ויציאת השבת <br/>
      תוכלו לקבל תזכורת כמה דקות לפני כניסת החג להתכונן ולהדליק נרות <br/>
      ולהעדכן בפרשת השבוע <br/>
      <br/>
      מקווה שתהנו מהשימוש באפליקציה <br/>
      שבת שלום לכולנו <br/>
      <br/>
      ZwiClick <br/>
      </p>

    </div>
  )
}

export default About