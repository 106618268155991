import React, { useState, useEffect } from 'react'
import RollingQuote from '../components/RollingQuote'
import { PiStarOfDavid } from 'react-icons/pi'
import { TbCandle } from 'react-icons/tb'
import { BsDot } from 'react-icons/bs'
import { Link } from 'react-router-dom'



const ShabatPage = () => {

    const [infoRecord, setInfoRecord] = useState()
    const textStyle = { "textShadow": "0 0 4px #ffffff, 0 0 8px #ffffff, 0 0 12px #ffffff, 0 0 16px #ffffff" }
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {

        let getShabatRecordUrl = backendUrl + "?api=get_shabat_info_record"
        fetch(getShabatRecordUrl, { method: 'GET',  headers: {'Accept': 'application/json',}})
            .then(res => res.json())
            .then(
                (result) => {
                     setInfoRecord(result)
                },
                (error) => {
                    console.log(error)
                }
            )

    }, [])

    function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero indexed
    const year = date.getFullYear();

    // Pad single digits with a leading zero
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}.${formattedMonth}.${year}`;
    }


    return (
        <div className='bg-[#ffffff60] shadow-lg w-10/12 h-[90%] flex flex-col items-center gap-6 text-xl'>
            <div className='mt-16'>
                <h1 style={textStyle} className='tracking-wide font-bold text-3xl pt-4'> שבת שלום </h1>
                <br></br>
                <p style={textStyle}> התאריך היום: {infoRecord ? formatDate(infoRecord["today_date"]) : ""} </p>
                <p style={textStyle}> השבת הקרובה : {infoRecord ? formatDate(infoRecord["shabat_date"]) : ""} </p>
                <p style={textStyle}> {infoRecord ? infoRecord["heb_date"] : ""} </p>
            </div>

            <PiStarOfDavid className='text-2xl text-[#2b8b9c]' />


            {/*<div style={{ backgroundImage: `url(${candleImage})` }} className="w-4/12 h-20 my-8 bg-cover text-center"></div>
            <div className="w-4/12 h-16 my-8 bg-cover text-center"></div>
            */}
            <table className="mt-4 [&_td]:w-1/4 rounded-xl bg-[#ffffff60] overflow-hidden">
                <tbody className='[&_td]:py-1 [&_th]:py-1 [&_tr:nth-child(odd)]:bg-[#ffffff40]'>
                    <tr className='[&_th]:font-normal'>
                        <th></th>
                        <th>כניסה</th>
                        <th>יציאה</th>
                    </tr>
                    <tr>
                        <td>ירושלים</td>
                        <td>{infoRecord ? infoRecord["Jerusalem_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["Jerusalem_out"].slice(0, -3) : ""}</td>
                    </tr>
                    <tr>
                        <td>תל אביב</td>
                        <td>{infoRecord ? infoRecord["TelAviv_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["TelAviv_out"].slice(0, -3) : ""}</td>
                    </tr>
                    <tr>
                        <td>חיפה</td>
                        <td>{infoRecord ? infoRecord["Hayfa_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["Hayfa_out"].slice(0, -3) : ""}</td>
                    </tr>
                    <tr>
                        <td>באר שבע</td>
                        <td>{infoRecord ? infoRecord["BeerSheva_in"].slice(0, -3) : ""}</td>
                        <td>{infoRecord ? infoRecord["BeerSheva_out"].slice(0, -3) : ""}</td>
                    </tr>
                </tbody>
            </table>
            <BsDot className='text-[#2b8b9c] text-lg' />
            
                <Link to="/parasha" className='rounded-xl mt-2 flex flex-col overflow-hidden items-center w-10/12'>
                    <p className={"bg-[#ffffffa0] w-full py-1"}> פרשת השבוע: {infoRecord ? infoRecord["parasha"] : ""} </p>

                    <RollingQuote />
                </Link>
        </div>
    );
}

export default ShabatPage
